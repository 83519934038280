import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../actions/securityActions";

class Footer extends Component {
  render() {
    

    return (
        <footer>
            <div className="footer">
              <p>
                    Copyright &copy; 2019 Designed by Mr.Lpz 
              </p>
                <p>
                    <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">鄂ICP备18023347号-2</a>
                </p>
            </div>
        </footer>
    );
  }
}


const mapStateToProps = state => ({
  security: state.security
});

export default connect(
  mapStateToProps,
  { logout }
)(Footer);
